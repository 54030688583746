.about-mining {
  padding: 200px 0;
  color: white;
  background-color: #16171b;

  .about-mining-content {
    width: 90%;
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;

    .left-section {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .faces {
        width: 90%;
        min-width: 300px;
        image-rendering: crisp-edges;
      }
    }
    .right-section {
      padding-left: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1060px) {
  .about-mining {
    padding: 100px 0;
    .about-mining-content {
      text-align: center;
      flex-direction: column;
      .left-section {
        .faces {
          width: 60%;
          min-width: 300px;
          image-rendering: crisp-edges;
          margin: 0 auto;
        }
      }
      .right-section {
        padding-left: 0;
        margin-top: 40px;
        .section-title {
          font-size: 30px;
        }
      }
    }
  }
}
