.services {
  background-color: #ffffff;

  .services-content {
    width: 90%;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    padding: 200px 5%;
    // padding-bottom: 330px;

    .left-section {
      width: 50%;
      padding-bottom: 50px;

      .description-container {
        width: max-content;
        display: block;
        margin: 0 auto;
        margin-top: 300px;
        position: sticky;
        top: calc(100vh - 64%);

        .section-description {
          margin-bottom: 26px;
          width: 468px;
        }
      }
    }
    .right-section {
      width: 100%;
      width: 800px;
      padding-left: 20px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .services {
    .services-content {
      flex-direction: column;
      padding: 150px 0;

      .left-section {
        width: 100%;
        padding: 0;
        margin-bottom: 100px;

        .description-container {
          display: block;
          margin: 0 auto;
          text-align: center;
          width: 90%;

          .section-description {
            margin: 0 auto;
            margin-bottom: 26px;
            width: 90%;
          }
        }
      }
      .right-section {
        width: 100%;
        padding-left: 0;
        margin: 0 auto;
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .services {
    .services-content {
      flex-direction: column;
      padding: 100px 0;

      .left-section {
        width: 100%;
        padding: 0;
        margin-bottom: 100px;
        .description-container {
          .section-title {
            font-size: 30px;
          }
        }
      }
      .right-section {
        width: 100%;
        padding-left: 0;
        margin: 0 auto;
      }
    }
  }
}
