.hero {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.2s ease-in-out;
  // pointer-events: none;
}
.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  max-width: 1920px;
  margin: 0 auto;
  min-height: calc(100vh - 100px);

  .section-title {
    width: 600px;
    font-size: 46px;
    font-weight: 700;
    color: #343941;
    display: block;
    margin-bottom: 26px;
    line-height: 1.4;
  }
  .hero-sub {
    width: 30%;
    font-size: 18px;
    font-weight: 400;
    color: #343941;
    display: block;
  }
}
.hero-bottom {
  width: 100%;
  position: absolute;
  display: flex;
  top: calc(100vh - 100px);

  .button-white {
    width: max-content;
    margin: 0 auto;
  }
}
.hero-content {
  max-height: 50vh;
}
.hero,
.nav-links,
.hero-content,
#hero-scene,
#hero-scene-dark,
.burguer {
  transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
#root.is-exploring {
  overflow: hidden;
  height: 100vh;
  #hero-scene,
  #hero-scene-dark {
    padding-top: 0;
  }
  .hero-content {
    // min-height: 0;
    // max-height: 0;
    // overflow: hidden;
  }
  #headerbar {
    background-color: transparent !important;
  }
  .nav-links,
  .hero-content,
  .hero,
  .burguer {
    opacity: 0;
    pointer-events: none;
  }
}

@media screen and (max-width: 1280px) {
  .hero-content {
    .section-title {
      width: 500px;
    }
    .hero-sub {
      width: 440px;
    }
  }
}
@media screen and (max-width: 930px) {
  #hero-scene,
  #hero-scene-dark {
    padding-top: 38vh;
  }
  .hero-content {
    min-height: 46vh;
    .section-title {
      width: 100%;
      text-align: center;
    }
    .hero-sub {
      width: 100%;
      text-align: center;
    }
  }
}
@media screen and (max-width: 640px) {
  #hero-scene,
  #hero-scene-dark {
    padding-top: 36vh;
  }
  .hero-content {
    min-height: 44vh;
    .section-title {
      font-size: 30px;
      width: 100%;
      text-align: center;
    }
    .hero-sub {
      width: 100%;
      text-align: center;
    }
  }
}
@media screen and (max-width: 390px) {
  #hero-scene,
  #hero-scene-dark {
    padding-top: 50vh;
  }
  .hero-content {
    min-height: 58vh;
    .section-title {
      font-size: 30px;
      width: 100%;
      text-align: center;
    }
    .hero-sub {
      width: 100%;
      text-align: center;
    }
  }
}
@media screen and (max-width: 360px) {
  #hero-scene,
  #hero-scene-dark {
    padding-top: 55vh;
  }
  .hero-content {
    min-height: 68vh;
    .section-title {
      font-size: 26px;
      width: 100%;
      text-align: center;
    }
    .hero-sub {
      width: 100%;
      text-align: center;
    }
  }
}
