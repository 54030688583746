.mining-project {
  padding: 200px 0;
  color: white;
  //   background-color: #25272d;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #18191e;
    transform: skewY(1deg);
  }
  &.highlight {
    &::before {
      background-color: #16171b;
    }
  }

  .mining-project-content {
    width: 90%;
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
  }

  .left-section {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 30px;
    flex: 1;
    height: 50%;

    .mockup {
      width: 100%;
      min-width: 500px;
      image-rendering: crisp-edges;
    }

    .project-client {
      margin: 20px 0;
      display: inline-block;
      width: 100%;

      .briefcase {
        display: inline-block;
        width: 18px !important;
      }

      .project {
        display: inline-block;
        margin-left: 10px;
      }
      .tags {
        display: block;
      }
    }
  }

  .right-section {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;

    .mockup {
      width: 100%;
      min-width: 500px;
      image-rendering: crisp-edges;
      justify-content: center;
      flex: 1;
    }

    .project-client {
      margin: 20px 0;
      display: inline-block;
      width: 100%;

      .briefcase {
        display: inline-block;
        width: 18px !important;
      }

      .project {
        display: inline-block;
        margin-left: 10px;
      }
      .tags {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 1060px) {
  .mining-project:nth-child(2n) {
    .mining-project-content {
      flex-direction: column-reverse;
      .right-section {
        margin-top: 0;
        .mockup {
          margin-bottom: 40px;
        }
      }
    }
  }
  .mining-project {
    padding: 100px 0;
    .mining-project-content {
      flex-direction: column;
      text-align: center;
      .left-section {
        padding: 0;
        .section-title {
          font-size: 30px;
        }
        .mockup {
          width: 60%;
          min-width: 300px;
          margin: 0 auto;
        }
      }
      .right-section {
        padding-left: 0;
        margin-top: 40px;
        .section-title {
          font-size: 30px;
        }
        .mockup {
          width: 60%;
          min-width: 300px;
          margin: 0 auto;
        }
      }
    }
  }
}
