.db-service {
  background-color: #16171b;
  color: #fff;

  .db-service-content {
    width: 90%;
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;
    padding: 180px 0;

    .upper-section {
      width: 100%;

      img {
        display: block;
        margin: 0 auto;
        height: 160px;
      }
    }
    .lower-section {
      padding-top: 30px;
      width: 100%;
      max-width: 1000px;
      text-align: center;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 1060px) {
  .db-service {
    .db-service-content {
      padding: 100px 0;
      .lower-section {
        .section-title {
          font-size: 30px;
        }
      }
    }
  }
}
