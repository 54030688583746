label {
  display: inline-block;
  margin-bottom: 10px;
}

.contact-form {
  background: rgb(30, 32, 39);
  background: linear-gradient(
    90deg,
    rgba(30, 32, 39, 1) 0%,
    rgba(30, 32, 39, 1) 50%,
    rgb(38, 44, 53) 100%
  );
  padding: 120px 0;
  overflow: hidden;

  .contact-form-content {
    width: 90%;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    .left-section {
      flex: 1;
      color: #ffffff;

      .section-title {
        margin-bottom: 50px;
      }

      .inputs {
        margin: 20px 0;

        input {
          font-size: 16px;
          background-color: #343941;
          color: #ffffff;
          padding-left: 14px;
          width: 100%;
          display: block;
          height: 46px;
          margin-bottom: 30px;
          border-radius: 3px;
          border: 1px solid #424851;
          transition: 0.2s ease-out;

          &::placeholder {
            font-size: 16px;
            color: rgba(255, 255, 255, 0.7);
            margin-left: 8px;
          }
          &:focus {
            border: 1px solid #00c9f7;
            outline: none;
          }
          &:hover {
            border: 1px solid #00c9f7;
            outline: none;
            box-shadow: 0px 0px 10px 1px rgba(66, 164, 255, 0.2);
          }
        }

        textarea {
          resize: none;
          font-size: 16px;
          background-color: #343941;
          color: #ffffff;
          width: 100%;
          height: 170px;
          padding-top: 10px;
          padding-left: 14px;
          border-radius: 3px;
          border: 1px solid #424851;
          transition: 0.2s ease-out;

          &::placeholder {
            font-size: 16px;
            color: rgba(255, 255, 255, 0.7);
          }
          &:focus {
            border: 1px solid #00c9f7;
            outline: none;
          }
          &:hover {
            border: 1px solid #00c9f7;
            outline: none;
            box-shadow: 0px 0px 10px 1px rgba(66, 164, 255, 0.2);
          }
        }
      }
    }
    .right-section {
      flex: 1;
      max-height: 610px;
      position: relative;
    }
  }
}
@media screen and (max-width: 1200px) {
  .contact-form {
    padding: 100px 0;
    .contact-form-content {
      flex-direction: column;
      .left-section {
        z-index: 10;
      }

      .right-section {
        flex: 1;
        height: 200px;
        position: relative;
      }
    }
  }
}
@media screen and (max-width: 560px) {
  .contact-form {
    .contact-form-content {
      .left-section {
        position: relative;
        z-index: 10;
        .section-title {
          font-size: 30px;
        }
      }
      .right-section {
        flex: 1;
        height: 100%;
        position: relative;
        top: 40px;
      }
    }
  }
}
