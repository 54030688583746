.corfo-card {
  padding: 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  margin: 0px 20px;
  border: 1px solid #d8dcea;
  box-shadow: 0px 19px 13px -14px rgba(0, 0, 0, 0.14);
  cursor: grab;

  .card-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .card-description {
    width: 600px;
    opacity: 0.7;
  }
}

@media screen and (max-width: 920px) {
  .corfo-card {
    margin: 0 auto;
    margin-bottom: 50px;
    .card-description {
      width: 100%;
      max-width: 570px;
    }
  }
}
