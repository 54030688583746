.tag-sky {
  display: inline-block;
  width: max-content;
  padding: 4px 16px;
  border-radius: 20px;
  background-color: #00c9f7;
  margin-right: 10px;
}
.tag-green {
  display: inline-block;
  width: max-content;
  padding: 4px 16px;
  border-radius: 20px;
  background-color: #46bd8d;
  margin-right: 10px;
}
.tag-blue {
  display: inline-block;
  width: max-content;
  padding: 4px 16px;
  border-radius: 20px;
  background-color: #4980ac;
  margin-right: 10px;
}
