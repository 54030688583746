.mining-projects-intro {
  height: max-content;
  background-color: #16171b;
  color: #fff;

  .mining-projects-intro-content {
    width: 80%;
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;

    .upper-section {
      padding: 80px 0;
      height: 50%;
      width: 800px;
      text-align: center;
      margin: 0 auto;

      .section-description {
        opacity: 0.8;
      }
    }
    .lower-section {
      height: 50%;
      width: 100%;

      img {
        display: block;
        margin: 0 auto;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 1060px) {
  .mining-projects-intro {
    .mining-projects-intro-content {
      width: 90%;
      .upper-section {
        width: 100%;
        .section-title {
          font-size: 30px;
        }
      }
    }
  }
}
