@import "./components.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  letter-spacing: 0.5px;
  scroll-padding-top: 110px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.w100 {
  width: 100%;
}
.section-name {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 26px;
  letter-spacing: 2px;
  opacity: 0.7;
}
.section-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 26px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}
.letter {
  display: inline-block;
  line-height: 1em;
  opacity: 0;
}
.section-description {
  font-size: 18px;
  opacity: 0.8;
  font-weight: 200;
}
html,
body {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  background-color: #16171b;
}

.App {
  height: 100%;
}

a {
  color: #fff;
  text-decoration: none;
}

//------------------------------Dark mode-----------------------------
.dark {
  .button-white {
    background-color: #343941;
    box-shadow: 0px 10px 6px -4px hsla(227, 13%, 14%, 0.3);
    .a-button {
      color: #ffffff;
    }
  }
  .headerbar {
    &.scrolled {
      background-color: #1e2027;
    }
    .navigation {
      .nav-links {
        a {
          color: #ffffff;
        }
      }
    }
  }

  .hero-content {
    color: #ffffff;
    .section-title {
      color: #ffffff;
    }
    .hero-sub {
      color: #ffffff;
    }
  }
  .projects {
    background-color: #1e2027;
    color: #fff;

    svg {
      path {
        fill: #fff;
      }
    }
  }
  .corfo-card {
    background-color: #292c36;
    border: 1px solid #292c36;
  }
}
@media screen and (max-width: 930px) {
  .dark {
    .headerbar {
      .nav-links {
        background-color: #1e2027;
      }
    }
  }
}

.zoom-in {
  transform: scale(0.8);
  transition: 0.5s ease-in-out;
  opacity: 0;
  &-active {
    transform: scale(1);
    opacity: 1;
  }
}
.overflow-hidden {
  overflow: hidden;
}

#loading-container > div {
	z-index: 200;
	background: #fff;
	opacity: 1;
}
