.footer {
  position: relative;
  z-index: 2;
  background-color: #25272d;
  padding: 50px 0;
  color: #ffffff;

  .footer-content {
    width: 90%;
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    justify-content: space-between;

    .pages-links {
      display: flex;
      flex: 1;
      // background-color: green;
      justify-content: space-between;

      .logo {
        // background-color: blue;
      }
      .jobs,
      .social,
      .contact {
        margin-left: 40px;
        // background-color: red;
        .item {
          margin-left: 0;
        }
      }
    }

    .location {
      flex: 1;
      margin-left: 6%;

      iframe {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }
  }
}
.footer-title {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

.item {
  display: block;
  opacity: 0.7;
  font-weight: 400;
  margin-bottom: 10px;
}

@media screen and (max-width: 960px) {
  .footer {
    .footer-content {
      flex-direction: column;
      .pages-links {
        margin-bottom: 50px;
      }
      .location {
        margin-left: 0;
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .footer {
    .footer-content {
      flex-direction: column;
      .pages-links {
        flex-direction: column;
        .contact {
          margin-left: 0;
        }
        .logo,
        .jobs,
        .social {
          margin-left: 0px;
          margin-bottom: 50px;
          // background-color: red;
          .item {
            margin-left: 0;
          }
        }
      }
      .location {
        margin-left: 0;
      }
    }
  }
}
