.headerbar {
  width: 100%;
  height: 100px;
  display: flex;
  position: fixed;
  align-items: center;
  padding: 0px 5%;
  z-index: 100;
  transition: 0.2s ease;
  justify-content: space-between;

  &.scrolled {
    height: 110px;
    background-color: #fff;
  }
  .navigation {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    width: 100%;
    position: relative;

    .shortcuts {
      display: inline-block;
      position: absolute;
      right: 0;
      bottom: 7px;
    }

    .logo {
      transform: scale(1);
    }

    .nav-links {
      vertical-align: middle;
      margin-left: 20px;
      flex: 1;

      .scroll {
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        color: #343941;
        padding: 10px 20px;
        text-decoration: none;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          letter-spacing: 1px;
        }
      }
    }
  }
  .burguer {
    display: none;
    cursor: pointer;
  }
  .burguer div {
    width: 28px;
    height: 3px;
    margin: 5px;
    border-radius: 20px;
    background-color: rgb(5, 171, 232);
    transition: all 0.3s ease;
  }
}

@media screen and (max-width: 930px) {
  .headerbar {
    &.scrolled {
      height: 80px;
      background-color: #fff;
    }
    .burguer {
      display: block;
    }
    .navigation {
      position: unset;
      .logo {
        transform: scale(0.9);
      }

      .shortcuts {
        display: flex;
        flex-direction: column;
        position: unset;
        opacity: 0;

        .button-darkblue,
        .button-default {
          margin: 0;
          margin: 16px 0;
        }
      }
    }
  }
  .nav-links {
    position: absolute;
    z-index: -1;
    right: 0px;
    height: 100vh;
    top: 0;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    opacity: 0;
    transform: translateX(100%);
    background-color: #fff;

    // transition: 0.3s ease-in-out;
    .scroll {
      font-size: 18px;
      margin: 16px;
      opacity: 0;
    }
  }
}
.nav-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 0.3s;
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}
