.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}
@media screen and (max-width: 1400px) {
  .grid {
    margin: 0 auto;
    width: 800px;
    grid-gap: 50px;
  }
}
@media screen and (max-width: 920px) {
  .grid {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    grid-gap: 50px;
    grid-template-columns: 1fr;
  }
}

// @media screen and (max-width: 920px) {
//   .grid {
//     margin: 0 auto;
//     background-color: red;
//     width: 100%;
//     grid-template-columns: 1fr;
//     grid-gap: 50px;
//   }
// }
