.banner {
  width: 100%;
  margin: 0 auto;
  padding: 80px 0px;
  background-color: #25272d;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;

  .button-darkblue {
    margin: 0;
  }

  .section-title {
    width: 50%;
    margin-bottom: 16px;
    z-index: 1;
  }

  .section-description {
    margin-bottom: 16px;
  }
}

#topographic {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#topographic path {
  fill: #393d47;
  stroke-width: 1;
  stroke-miterlimit: 0;
  stroke: #25272d;
  stroke-dasharray: 500;
  stroke-dashoffset: 1000;
  -webkit-animation: dash 5s linear alternate infinite;
  animation: dash 5s linear alternate infinite;
}

@-webkit-keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@media screen and (max-width: 1060px) {
  .banner {
    .section-title {
      width: 90%;
      margin-bottom: 16px;
      z-index: 1;
    }
  }
}
@media screen and (max-width: 640px) {
  .banner {
    .section-title {
      font-size: 30px;
      width: 90%;
      margin-bottom: 16px;
      z-index: 1;
    }
  }
}
