.card {
  width: 346px;
  background-color: #fff;
  padding: 28px;
  margin: 0 auto;
  margin-bottom: 80px;
  border-radius: 6px;
  border: 1px solid #d8dcea;
  box-shadow: 0px 17px 43px -7px rgba(0, 0, 0, 0.06);
  transition: transform 0.3s ease-out;
  cursor: default;

  &:hover {
    transform: translate(0, -5px);
  }

  .image {
    width: 58px;
    height: 58px;
    margin-bottom: 26px;
    background-color: #e2e5ed;
    border-radius: 100px;
  }
  .card-title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .card-description {
    font-size: 16px;
    opacity: 0.7;
    letter-spacing: 0.5px;
    line-height: 1.7;
  }
  &:nth-of-type(even) {
    top: 210px;
    position: relative;
  }
}
@media screen and (max-width: 1400px) {
  .card {
    width: 80%;
    min-width: 346px;
  }
}

@media screen and (max-width: 920px) {
  .card {
    margin: 0 auto;

    &:nth-of-type(even) {
      top: 0;
      position: relative;
    }
  }
}

@media screen and (max-width: 560px) {
  .card {
    margin: 0 auto;
    min-width: unset;
    width: 100%;

    &:nth-of-type(even) {
      top: 0;
      position: relative;
    }
  }
}
