.button-default {
  position: relative;
  background-color: #00c9f7;
  border-radius: 100px;
  box-shadow: 0px 10px 20px -4px rgba(5, 172, 232, 0.4);
  border: none;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;

  .a-button {
    display: block;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 26px;
    border-radius: 100px;
    text-decoration: none;
    color: #ffffff !important;
  }

  &::after {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 0;
    left: -200%;
    width: 200%;
    height: 100%;
    transform: skewX(-20deg);
    background-image: linear-gradient(
      to right,
      rgba(#fff, 0),
      rgba(#fff, 0.3),
      rgba(#fff, 0)
    );
  }
  &:hover::after {
    animation: shine 1.6s ease;
  }
  &:active {
    transform: translateY(1px);
    box-shadow: 0px 0px 20px -4px rgba(5, 172, 232, 0.6);
  }
}
.button-darkblue {
  position: relative;
  background-color: #4390cd;
  padding: 14px 26px;
  border-radius: 100px;
  box-shadow: 0px 10px 20px -4px rgba(5, 172, 232, 0.4);
  border: none;
  overflow: hidden;
  cursor: pointer;
  margin-left: 20px;
  transition: all 0.2s ease;

  .a-button {
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: #ffffff !important;
    padding: 0 !important;
    margin-right: 4px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -200%;
    width: 200%;
    height: 100%;
    transform: skewX(-20deg);
    background-image: linear-gradient(
      to right,
      rgba(#fff, 0),
      rgba(#fff, 0.3),
      rgba(#fff, 0)
    );
  }
  &:hover::after {
    animation: shine 1.6s ease;
  }
  &:active {
    transform: translateY(1px);
    box-shadow: 0px 0px 20px -4px rgba(5, 172, 232, 0.7);
  }
}
.button-white {
  position: relative;
  background-color: #ffffff;
  padding: 14px 26px;
  border-radius: 100px;
  box-shadow: 0px 10px 6px -4px rgba(190, 207, 231, 0.1);
  border: none;
  overflow: hidden;
  cursor: pointer;
  margin-left: 20px;
  transition: all 0.2s ease;

  .a-button {
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: #424851;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -200%;
    width: 200%;
    height: 100%;
    transform: skewX(-20deg);
    background-image: linear-gradient(
      to right,
      rgba(#fff, 0),
      rgba(#fff, 0.3),
      rgba(#fff, 0)
    );
  }
  &:hover::after {
    animation: shine 1.6s ease;
  }
  &:active {
    transform: translateY(1px);
    box-shadow: 0px 0px 20px -4px rgba(5, 172, 232, 0.7);
  }
}

.button {
  .a-button {
    display: inline-block;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

@keyframes shine {
  100% {
    left: 200%;
  }
}
