.projects {
  padding-top: 12px;
  padding-bottom: 80px;
  background-color: #fbfbfb;

  svg {
    font-size: 130px;
    display: block;
    margin: 0 auto;
  }
}

.wrapper {
  width: max-content;
  display: flex;
  flex-direction: row;
  -webkit-overflow-scrolling: touch;
  margin: 0 auto;
  border-radius: 6px;
}

@media screen and (max-width: 920px) {
  .wrapper {
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  }
}
