.experience {
  padding-top: 160px;
  height: max-content;
  background-color: #ffffff;
  overflow: hidden;

  .experience-content {
    width: 80%;
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;

    .upper-section {
      height: 50%;
      width: 80%;
      text-align: center;
      margin: 0 auto;

      .section-description {
        opacity: 0.8;
      }
    }
    .lower-section {
      height: 100%;
      width: 100%;

      img {
        display: block;
        margin: 0 auto;
        width: 65%;
        image-rendering: crisp-edges;
        pointer-events: none;
      }
    }
  }
}

@media screen and (max-width: 1060px) {
  .experience {
    padding-top: 100px;
    .experience-content {
      width: 90%;
      .upper-section {
        width: 100%;
        .section-title {
          font-size: 30px;
        }
      }
      .lower-section {
        img {
          width: 100%;
          image-rendering: crisp-edges;
        }
      }
    }
  }
}
